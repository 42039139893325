module services {
    export module master {
        export class customAttributeService implements interfaces.master.ICustomAttributeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Product Types for a dropdown/autocomplete list
            GetDropDownListByCategory(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomAttribute/GetDropDownListByCategory", {
                    categoryId: '@categoryId',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetDropDownListByCategories(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomAttribute/GetDropDownListByCategories", {
                    categoryId: '@categoryId',
                    categoryId2: '@categoryId2',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetCustomAttributeDropDownTypeList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "CustomAttribute/GetCustomAttributeDropDownTypeList", {
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }


            GetCustomAttributeByCategory(categoryId: number, ownerEntityId: number): ng.resource.IResourceClass<interfaces.master.ICustomAttribute> {
                return this.$resource<interfaces.master.ICustomAttribute>(this.ENV.DSP_URL + "CustomAttribute/GetCustomAttributeByCategory", {
                    categoryId: categoryId,
                    ownerEntityId: ownerEntityId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            deleteCustomAttributeValue(customAttributeValueId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CustomAttribute/DeleteCustomAttributeValue", {
                    customAttributeValueId: customAttributeValueId
                }, {
                        query: {
                            method: 'POST'
                        }
                    });
            }

            generateMandatoryAttributes(id: number, categoryClassificationValueId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CustomAttribute/GenerateMandatoryAttributes", {
                    id: id,
                    categoryClassificationValueId: categoryClassificationValueId
                }, {
                        query: {
                            method: 'POST'
                        }
                    });
            }

        }
    }
    angular.module("app").service("customAttributeService", services.master.customAttributeService);
}